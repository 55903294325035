<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/customers">Customers</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a :href="`/customers/${_id}`">{{customer.email}}</a>
    </div>
    <div class="row issue-wrapper" v-if="customer._id">
      <div class="col col-auto-5 issue-col p-4 bg-white">
        <div class="row px-2">
          <div class="col-12 pt-2">
            <h6 class="text-dark text-header">
              Full Name
              <small class="text-secondary cursor-pointer float-right mt-2" v-if="formControl.avatarMenu" v-on:click="toggleAvatar">Change avatar</small>
            </h6>
            <input class="form-control" placeholder="Full name" v-model="customer.name" :disabled="!formControl.profile"/>
            <br>
            <h6 class="text-dark text-header">Email Address</h6>
            <input class="form-control" placeholder="Email Address" v-model="customer.email" disabled/>
            <br>
            <h6 class="text-dark text-header">Client Name</h6>
            <input class="form-control" placeholder="Client name" v-model="client.name" disabled/>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">Mobile Number</h6>
                <input class="form-control" placeholder="Mobile Number" v-model="customer.mobileNumber" :disabled="!formControl.profile"/>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Phone Number</h6>
                <input class="form-control" placeholder="Phone Number" v-model="customer.phoneNumber" :disabled="!formControl.profile"/>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">User Role</h6>
                <ModelSelect
                  v-model="userRole"
                  placeholder="Select user role"
                  :options="options.roles"
                  :isDisabled="true"
                >
                </ModelSelect>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Email Notifications</h6>
                <ModelSelect
                  v-model="customer.receiveEmail"
                  placeholder="Email Notifications"
                  :options="options.notifications"
                  :isDisabled="!formControl.profile"
                >
                </ModelSelect>
              </div>
            </div>
            <br>
            <div class="row">
              <div class="col">
                <h6 class="text-dark text-header">New Password</h6>
                <input class="form-control" :type="showPassword ? `text` : `password`" placeholder="Reset password" v-model="customer.resetPassword" :disabled="!formControl.profile"/>
                <div
                  class="btn btn-muted btn-sm p-3 float-right"
                  @mouseover="showPassword = true"
                  @mouseleave="showPassword = false"
                  style="position: relative; margin-top: -50px; z-index: 999"
                >
                  <i class="text-secondary fas" :class="showPassword ? `fa-eye-slash` : `fa-eye`"/>
                </div>
              </div>
              <div class="col">
                <h6 class="text-dark text-header">Confirm Password</h6>
                <input class="form-control" :type="showConfirmPassword ? `text` : `password`" placeholder="Confirm password" v-model="customer.confirmPassword" :disabled="!formControl.profile"/>
                <div
                  class="btn btn-muted btn-sm p-3 float-right"
                  @mouseover="showConfirmPassword = true"
                  @mouseleave="showConfirmPassword = false"
                  style="position: relative; margin-top: -50px; z-index: 999"
                >
                  <i class="text-secondary fas" :class="showConfirmPassword ? `fa-eye-slash` : `fa-eye`"/>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <br>
            <div class="w-100 text-right">
              <div :class="'btn btn-sm border-0 text-light px-3 py-2 d-inline ' + (customer.isActive ? 'btn-dark' : 'bg-success')" v-on:click="toggleStatusModal" v-if="formControl.status">
                <i :class="'text-white fas fa-' + (customer.isActive ? 'lock' : 'unlock')"></i>&nbsp; {{customer.isActive ? 'Deactivate' : 'Activate'}} customer
              </div>&nbsp;
              <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="updateCustomer" v-bind:class="[formControl.profile ? '' : 'element-disabled']">
                <i class="text-white fas fa-save"></i>&nbsp; Update customer
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 issue-col issue-properties shadow-0 border-md-left p-0 m-0" v-bind:class="[formControl.profile ? '' : 'element-disabled']">
        <div class="row px-2 m-0 h-100 w-100">
          <div class="col issue-col bg-white p-3">
            <div class="property-dynamic">
              <h6 class="pt-2 text-dark text-header">{{client.name}} Ticket Counter</h6>
              <a :href="`/tickets?client=${this.client.code}`">
                <div class="text-center my-4 cursor-pointer" v-tooltip="`View all tickets`">
                  <div class="percent-statistics">
                    <div>{{ticketStatistics.percentCompleted | rounded}}%</div>
                    <label>Completed from <label>{{ticketStatistics.totalCount}}</label> total tickets</label>
                  </div>
                </div>
              </a>
              <div class="row side-bar-stats mb-3 text-center">
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=pending`" v-tooltip="`View all pending tickets`">
                  <h3>{{ticketStatistics.totalPending | zeroPad}}</h3>
                  <small>Pending</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=acknowledged`" v-tooltip="`View all acknowledged tickets`">
                  <h3>{{ticketStatistics.totalAcknowledged | zeroPad}}</h3>
                  <small>Acknowledged</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=inProgress`" v-tooltip="`View all in progress tickets`">
                  <h3>{{ticketStatistics.totalinProgress | zeroPad}}</h3>
                  <small>In Progress</small>
                </a>
              </div>
              <div class="row side-bar-stats mb-3 text-center">
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=escalated`" v-tooltip="`View all escalated tickets`">
                  <h3>{{ticketStatistics.totalEscalated | zeroPad}}</h3>
                  <small>Escalated</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=resolved`" v-tooltip="`View all resolved tickets`">
                  <h3>{{ticketStatistics.totalResolved | zeroPad}}</h3>
                  <small>Resolved</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=closed`" v-tooltip="`View all closed tickets`">
                  <h3>{{ticketStatistics.totalClosed | zeroPad}}</h3>
                  <small>Closed</small>
                </a>
              </div>
              <hr class="my-4">
              <h6 class="pt-2 text-dark text-header">User Counter</h6>
              <a class="row side-bar-stats mb-3 text-center cursor-pointer" :href="`/customers?client=${this.client.code}`" v-tooltip="`View all customers`">
                <div class="col col-4">
                  <h3>{{customerStatistics.totalUsers | zeroPad}}</h3>
                  <small>Total</small>
                </div>
                <div class="col col-4">
                  <h3>{{customerStatistics.totalActiveUsers | zeroPad}}</h3>
                  <small>Active</small>
                </div>
                <div class="col col-4">
                  <h3>{{customerStatistics.totalInactiveUsers | zeroPad}}</h3>
                  <small>Inactive</small>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="avatar-menu" :transition="`fade`" :width="`620px`" :height="`auto`" :scrollable="true">
      <div class="p-5 row">
        <span v-for="avatar in $avatars" v-bind:key="avatar" class="col col-3">
          <img
            class="profile-avatar m-0 p-3"
            v-bind:class="[`/images/avatars/${avatar}` === customer.avatar ? 'avatar-active' : '']"
            v-on:click="setAvatar(`/images/avatars/${avatar}`)"
            :src="$getAvatar(`/images/avatars/${avatar}`)"
          />
        </span>
      </div>
    </modal>
    <modal name="toggle-status" :transition="`fade`" :width="`500px`" :height="`auto`" :scrollable="true">
      <ToggleStatusComponent :user="customer" @loader="loader($event)" @updateUserStatus="updateUserStatus"/>
    </modal>
  </div>
</template>

<script>
  import { ModelSelect } from 'vue-search-select'
  import ToggleStatusComponent from  '../UserModals/ToggleStatus.vue'

  export default {
    components: {
      ModelSelect,
      ToggleStatusComponent
    },
    data() {
      return {
        customer: {},
        client: {},
        showPassword: false,
        showConfirmPassword: false,
        ticketStatistics: {
          percentCompleted: 0,
          totalPending: 0,
          totalAcknowledged: 0,
          totalinProgress: 0,
          totalEscalated: 0,
          totalResolved: 0,
          totalClosed: 0,
          totalOpen: 0,
          totalCount: 0
        },
        customerStatistics: {
          totalUsers: 0,
          totalActiveUsers: 0,
          totalInactiveUsers: 0
        },
        totals: {},
        userRole: 'Customer',
        options: {
          bunits: [],
          roles: [
            { key: 'Customer', text: 'Customer', value: 'Customer', disabled: false, data: {isAgent: false, role: 'Customer'} }
          ],
          notifications: [
            { key: 'active', text: 'Receive email notifications', value: true },
            { key: 'inactive', text: 'Suspend email notifications', value: false }
          ]
        },
        formControl: {
          profile: true,
          resetPassword: true,
          avatarMenu: true,
          status: false
        },
        loaded: false
      }
    },
    props: {
      _id: String
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      toggleStatusModal: function(){
        this.$modal.toggle('toggle-status');
      },
      async updateUserStatus(){
        var status = !this.customer.isActive
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/users/${this._id}`,
            {
              user: {
                isActive: status
              },
              _id: this.customer._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.user && response.data.user._id) {
            await this.getCustomer()
            this.setForms()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: 'User successfully ' + (response.data.user.isActive ? 'activated' : 'deactivated')
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
        this.setForms()
        this.$modal.hide('toggle-status');
      },
      async getCustomer(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/users/${this._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          await this.validateUser(response.data.isValid, response.data.exists)
          if (response.data.isValid){
            this.customer = response.data.user
            this.client = this.customer.client
            this.customer.password = ''
            this.customer.resetPassword = ''
            this.customer.confirmPassword = ''
            await this.getTotals()
          }
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getTotals() {
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/client/totals?client=${this.client._id}`,
              {
                headers: {
                  'Authorization': this.$userData.authToken,
                  'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.ticketStatistics = response.data.ticketStatistics
          this.customerStatistics = response.data.customerStatistics
        } catch (error) {
          console.log(error)
        }
      },
      validateUser: async function(isValid, exists){
        this.setForms()
        if(!isValid){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: exists ? `You don't have access to this customer.` : `Customer does not exist`
          })
          await this.$sleep(500);
          this.$router.push('/customers')
        }
      },
      setForms: function(){
        if(this.$userData.user.role === 'Agent' || this.$userData.user.role === 'Customer')
          this.formControl = {
            profile: false,
            resetPassword: false,
            avatarMenu: false,
            status: false
          }
        else
          this.formControl = {
            profile: true,
            resetPassword: true,
            avatarMenu: true,
            status: true
          }
      },
      updateCustomer: function (){
        if(!this.formControl.profile)
          return ''
        this.errors = []
        if(this.customer.name.trim() === '')
          this.errors.push('Customer name cannot be empty')
        if(this.customer.email.trim() === '')
          this.errors.push('Email address cannot be empty')
        if(this.customer.resetPassword.trim().length > 0){
          if(this.customer.resetPassword.trim().length < 6)
            this.errors.push('Password must have 6 or more characters')
          if(this.customer.confirmPassword.trim() !== this.customer.resetPassword.trim())
            this.errors.push('Confirm password does not match new password')
        }
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Customer Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitCustomer()
        }
      },
      async submitCustomer(){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/users/${this._id}`,
            {
              user: {
                name: this.customer.name ? this.customer.name.trim() : '',
                phoneNumber: this.customer.phoneNumber ? this.customer.phoneNumber.trim() : '',
                mobileNumber: this.customer.mobileNumber ? this.customer.mobileNumber.trim() : '',
                receiveEmail: this.customer.receiveEmail,
                resetPassword: this.customer.resetPassword.trim() === "" ? null : this.customer.resetPassword.trim(),
                avatar: this.customer.avatar
              },
              _id: this._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.user && response.data.user._id) {
            this.getCustomer()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: 'Your changes have been saved.'
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
      },
      toggleAvatar: function(){
        this.$modal.toggle('avatar-menu');
      },
      setAvatar: function(avatar){
        this.customer.avatar = avatar
        this.toggleAvatar()
      },
      newUser: function(){
        this.$router.push({path: "/users/new", props: true, params: {isInternal: false }})
      }
    },
    mounted: function() {
      document.title = this.customer._id ? `${this.customer.name} | iRipple Helpdesk` : `Customers | iRipple Helpdesk`;
      this.getCustomer()
    },
    filters: {
      zeroPad: function(num){
        return String(num).padStart(4, '0')
      },
      rounded: function(num){
        return Math.round((num + Number.EPSILON) * 100) / 100
      }
    }
  }
</script>
