<template>
  <div class="p-5 history-modal text-center">
    <div v-if="user.isActive">
      This will deactivate {{user.name}}'s account. This user will no longer be able to login{{user.client ? ' and create' : ', create, and manage'}} tickets.
    </div>
    <div v-else>
      This will activate {{user.name}}'s account. This user will be able to login{{user.client ? ' and create' : ', create, and manage'}} tickets again.
    </div>
    <br>
    Are you sure you want to continue?
    <br><br>
    <div class="w-100 mt-3">
      <div class="btn border-0 px-4 py-2 btn-primary" v-on:click="updateUserStatus">Continue</div>&nbsp;
      <div class="btn border-0 px-4 py-2 btn-dark bg-escalated" v-on:click="cancelMethod">Cancel</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      user: Object
    },
    data() {
      return {
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      updateUserStatus: function(val){
          this.$emit('updateUserStatus', val)
      },
      cancelMethod: function(){
        this.$modal.toggle('toggle-status');
      }
    }
  }
</script>
